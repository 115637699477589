import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import '../css/start-now.scss'
import { Navbar } from '../components/navbar'
import '../css/contact-req.scss'
import CheckedIcon from '../images/checked.png'

const ContactRequestReceived = ({ data }) => {
	useEffect(() => {
		let timeout = setTimeout(() => {
			navigate("/")
		}, 1000);

		return () => {
			clearTimeout(timeout);
		}
	}, []);

	return (
		<>
			<Layout>
				<SEO title="Meeting Request Received" description="We have received your meeting request" />
				<div className="start-now-page main-wrapper">
					<Navbar />
					<div className="contact-req-wrapper">
						<img src={CheckedIcon} className="checked" />

						<h1>
							Your Request Has Been Received, Will contact you soon.
						</h1>
					</div>
					<div className="footer-section">
						<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
					</div>
				</div>
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query ContactRequestReceivedQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`

export default ContactRequestReceived
